<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :querySearch="getData"
  :currentPage="items.current"
  :pageCount="items.count"
  labelKeyAdd="etiStatementAdd"
).px-4
  FormAdd(slot="AddBlock")
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'SailorCertificateStatement',
  components: {
    FormAdd: () => import('./Form.vue')
  },
  data () {
    return {
      headers: [
        { value: 'number', text: this.$t('number'), sortable: false },
        { value: 'created_at', text: this.$t('createDate'), sortable: false },
        { value: 'date_meeting', text: this.$t('meetingDate'), sortable: false },
        { value: 'institution', text: this.$t('nameInstitution'), sortable: false },
        { value: 'course', text: this.$t('course'), sortable: false },
        { value: 'is_payed', text: this.$t('payment'), sortable: false },
        { value: 'status_document', text: this.$t('status'), sortable: false },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          action: (item) => this.$router.push({ name: 'certification-statements-info', params: { ...this.$route.params, documentID: item.id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.sailor.certificationStatement,
      isLoading: state => state.sailor.isLoading
    })
  },
  methods: {
    ...mapActions(['getCertificateStatements']),
    async getData (params) {
      this.getCertificateStatements({ ...this.$route.params, params })
    }

  }
}
</script>
